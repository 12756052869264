import { default as affiliates9NdrGlvFWaMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsf2TFyU2pIAMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngosrat1jnAHtPMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93v3iShkynMOMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as indexKKzgrZ9Z1nMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93VfjqQtX8cQMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as indexxnKE6GB8hYMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93AE3BXF4nf9Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93X5MbNKzipoMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as editViXTJTSCvEMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexyPoxDTIFlCMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analyticscbxDAGfUF7Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexFiidQF9T2HMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as index3W9WAKIp8sMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareaKdi7djZyUMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showjJ5LwseMfWMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexbNEaAV10mhMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsO2QzUdrfouMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guidespTZ56YTHnkMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homem0E3bH1xMeMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexwpmh4IiEPFMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationstbQ08xJ5gMMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as loginBSpIXBjZ9KMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templatesxduOeYpW00Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencersOOt21UxM0xMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_934FtvDGyoy2Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackorgSBHJ8zLMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callback3c9thcn5wdMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbacknnkMhaWTG8Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnerstYrcBszbeIMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93JSOVTwX7BOMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexuegcXwN2raMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingR9dafykHS7Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policy9z5qA1x3mmMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as registerIDDEklzyVAMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokenseAPvIqmKEEMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountLm6CcfAoU5Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminchXImMwvXTMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingcntZOcFBc2Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsF8luLsMhsvMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainYdln9EH9HXMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as indexYUOKdAT7EsMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordauLMRNVF1jMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profileML7fzCZv7tMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspaceskd7pzgqFs0Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingsGwBP19zWeTMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/settings.vue?macro=true";
import { default as errorrtrGImG9UNMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as successt6tGW0o03UMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditions9uZgoC1InUMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as editnTKXZpISKpMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as indextLkJmdJsTUMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as indexOJPDFt1Wh3Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as shareVRvUpn0LV5Meta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showRUybqr2dHLMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexG3jubknGJoMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexGkxoUSHHgLMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsqDQfuxIXWbMeta } from "/codebuild/output/src1307910395/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliates9NdrGlvFWaMeta?.name ?? "affiliates",
    path: affiliates9NdrGlvFWaMeta?.path ?? "/affiliates",
    meta: affiliates9NdrGlvFWaMeta || {},
    alias: affiliates9NdrGlvFWaMeta?.alias || [],
    redirect: affiliates9NdrGlvFWaMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewsf2TFyU2pIAMeta?.name ?? "database-views",
    path: database_45viewsf2TFyU2pIAMeta?.path ?? "/database-views",
    meta: database_45viewsf2TFyU2pIAMeta || {},
    alias: database_45viewsf2TFyU2pIAMeta?.alias || [],
    redirect: database_45viewsf2TFyU2pIAMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngosrat1jnAHtPMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngosrat1jnAHtPMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngosrat1jnAHtPMeta || {},
    alias: discount_45students_45academics_45ngosrat1jnAHtPMeta?.alias || [],
    redirect: discount_45students_45academics_45ngosrat1jnAHtPMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93v3iShkynMOMeta?.name ?? "features-slug",
    path: _91slug_93v3iShkynMOMeta?.path ?? "/features/:slug()",
    meta: _91slug_93v3iShkynMOMeta || {},
    alias: _91slug_93v3iShkynMOMeta?.alias || [],
    redirect: _91slug_93v3iShkynMOMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexKKzgrZ9Z1nMeta?.name ?? "features",
    path: indexKKzgrZ9Z1nMeta?.path ?? "/features",
    meta: indexKKzgrZ9Z1nMeta || {},
    alias: indexKKzgrZ9Z1nMeta?.alias || [],
    redirect: indexKKzgrZ9Z1nMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VfjqQtX8cQMeta?.name ?? "form-templates-slug",
    path: _91slug_93VfjqQtX8cQMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93VfjqQtX8cQMeta || {},
    alias: _91slug_93VfjqQtX8cQMeta?.alias || [],
    redirect: _91slug_93VfjqQtX8cQMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexxnKE6GB8hYMeta?.name ?? "form-templates",
    path: indexxnKE6GB8hYMeta?.path ?? "/form-templates",
    meta: indexxnKE6GB8hYMeta || {},
    alias: indexxnKE6GB8hYMeta?.alias || [],
    redirect: indexxnKE6GB8hYMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93AE3BXF4nf9Meta?.name ?? "form-templates-industries-slug",
    path: _91slug_93AE3BXF4nf9Meta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93AE3BXF4nf9Meta || {},
    alias: _91slug_93AE3BXF4nf9Meta?.alias || [],
    redirect: _91slug_93AE3BXF4nf9Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93X5MbNKzipoMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93X5MbNKzipoMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93X5MbNKzipoMeta || {},
    alias: _91slug_93X5MbNKzipoMeta?.alias || [],
    redirect: _91slug_93X5MbNKzipoMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: editViXTJTSCvEMeta?.name ?? "forms-slug-edit",
    path: editViXTJTSCvEMeta?.path ?? "/forms/:slug()/edit",
    meta: editViXTJTSCvEMeta || {},
    alias: editViXTJTSCvEMeta?.alias || [],
    redirect: editViXTJTSCvEMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexyPoxDTIFlCMeta?.name ?? "forms-slug",
    path: indexyPoxDTIFlCMeta?.path ?? "/forms/:slug()",
    meta: indexyPoxDTIFlCMeta || {},
    alias: indexyPoxDTIFlCMeta?.alias || [],
    redirect: indexyPoxDTIFlCMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showjJ5LwseMfWMeta?.name ?? undefined,
    path: showjJ5LwseMfWMeta?.path ?? "/forms/:slug()/show",
    meta: showjJ5LwseMfWMeta || {},
    alias: showjJ5LwseMfWMeta?.alias || [],
    redirect: showjJ5LwseMfWMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analyticscbxDAGfUF7Meta?.name ?? "forms-slug-show-analytics",
    path: analyticscbxDAGfUF7Meta?.path ?? "analytics",
    meta: analyticscbxDAGfUF7Meta || {},
    alias: analyticscbxDAGfUF7Meta?.alias || [],
    redirect: analyticscbxDAGfUF7Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexFiidQF9T2HMeta?.name ?? "forms-slug-show",
    path: indexFiidQF9T2HMeta?.path ?? "",
    meta: indexFiidQF9T2HMeta || {},
    alias: indexFiidQF9T2HMeta?.alias || [],
    redirect: indexFiidQF9T2HMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: index3W9WAKIp8sMeta?.name ?? "forms-slug-show-integrations",
    path: index3W9WAKIp8sMeta?.path ?? "integrations",
    meta: index3W9WAKIp8sMeta || {},
    alias: index3W9WAKIp8sMeta?.alias || [],
    redirect: index3W9WAKIp8sMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: shareaKdi7djZyUMeta?.name ?? "forms-slug-show-share",
    path: shareaKdi7djZyUMeta?.path ?? "share",
    meta: shareaKdi7djZyUMeta || {},
    alias: shareaKdi7djZyUMeta?.alias || [],
    redirect: shareaKdi7djZyUMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexbNEaAV10mhMeta?.name ?? "forms-create",
    path: indexbNEaAV10mhMeta?.path ?? "/forms/create",
    meta: indexbNEaAV10mhMeta || {},
    alias: indexbNEaAV10mhMeta?.alias || [],
    redirect: indexbNEaAV10mhMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsO2QzUdrfouMeta?.name ?? "forms-uploads",
    path: uploadsO2QzUdrfouMeta?.path ?? "/forms/uploads",
    meta: uploadsO2QzUdrfouMeta || {},
    alias: uploadsO2QzUdrfouMeta?.alias || [],
    redirect: uploadsO2QzUdrfouMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guidespTZ56YTHnkMeta?.name ?? "guides",
    path: guidespTZ56YTHnkMeta?.path ?? "/guides",
    meta: guidespTZ56YTHnkMeta || {},
    alias: guidespTZ56YTHnkMeta?.alias || [],
    redirect: guidespTZ56YTHnkMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homem0E3bH1xMeMeta?.name ?? "home",
    path: homem0E3bH1xMeMeta?.path ?? "/home",
    meta: homem0E3bH1xMeMeta || {},
    alias: homem0E3bH1xMeMeta?.alias || [],
    redirect: homem0E3bH1xMeMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexwpmh4IiEPFMeta?.name ?? "index",
    path: indexwpmh4IiEPFMeta?.path ?? "/",
    meta: indexwpmh4IiEPFMeta || {},
    alias: indexwpmh4IiEPFMeta?.alias || [],
    redirect: indexwpmh4IiEPFMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationstbQ08xJ5gMMeta?.name ?? "integrations",
    path: integrationstbQ08xJ5gMMeta?.path ?? "/integrations",
    meta: integrationstbQ08xJ5gMMeta || {},
    alias: integrationstbQ08xJ5gMMeta?.alias || [],
    redirect: integrationstbQ08xJ5gMMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: loginBSpIXBjZ9KMeta?.name ?? "login",
    path: loginBSpIXBjZ9KMeta?.path ?? "/login",
    meta: loginBSpIXBjZ9KMeta || {},
    alias: loginBSpIXBjZ9KMeta?.alias || [],
    redirect: loginBSpIXBjZ9KMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templatesxduOeYpW00Meta?.name ?? "my-templates",
    path: my_45templatesxduOeYpW00Meta?.path ?? "/my-templates",
    meta: my_45templatesxduOeYpW00Meta || {},
    alias: my_45templatesxduOeYpW00Meta?.alias || [],
    redirect: my_45templatesxduOeYpW00Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencersOOt21UxM0xMeta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencersOOt21UxM0xMeta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencersOOt21UxM0xMeta || {},
    alias: notion_45ambassadors_45influencersOOt21UxM0xMeta?.alias || [],
    redirect: notion_45ambassadors_45influencersOOt21UxM0xMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_934FtvDGyoy2Meta?.name ?? "notion-form-slug",
    path: _91slug_934FtvDGyoy2Meta?.path ?? "/notion-form/:slug()",
    meta: _91slug_934FtvDGyoy2Meta || {},
    alias: _91slug_934FtvDGyoy2Meta?.alias || [],
    redirect: _91slug_934FtvDGyoy2Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbackorgSBHJ8zLMeta?.name ?? "notion-callback",
    path: callbackorgSBHJ8zLMeta?.path ?? "/notion/callback",
    meta: callbackorgSBHJ8zLMeta || {},
    alias: callbackorgSBHJ8zLMeta?.alias || [],
    redirect: callbackorgSBHJ8zLMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callback3c9thcn5wdMeta?.name ?? "notion-guest-callback",
    path: guest_45callback3c9thcn5wdMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callback3c9thcn5wdMeta || {},
    alias: guest_45callback3c9thcn5wdMeta?.alias || [],
    redirect: guest_45callback3c9thcn5wdMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbacknnkMhaWTG8Meta?.name ?? "oauth-callback",
    path: callbacknnkMhaWTG8Meta?.path ?? "/oauth/callback",
    meta: callbacknnkMhaWTG8Meta || {},
    alias: callbacknnkMhaWTG8Meta?.alias || [],
    redirect: callbacknnkMhaWTG8Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnerstYrcBszbeIMeta?.name ?? "partners",
    path: partnerstYrcBszbeIMeta?.path ?? "/partners",
    meta: partnerstYrcBszbeIMeta || {},
    alias: partnerstYrcBszbeIMeta?.alias || [],
    redirect: partnerstYrcBszbeIMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93JSOVTwX7BOMeta?.name ?? "password-reset-token",
    path: _91token_93JSOVTwX7BOMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93JSOVTwX7BOMeta || {},
    alias: _91token_93JSOVTwX7BOMeta?.alias || [],
    redirect: _91token_93JSOVTwX7BOMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexuegcXwN2raMeta?.name ?? "password-reset",
    path: indexuegcXwN2raMeta?.path ?? "/password/reset",
    meta: indexuegcXwN2raMeta || {},
    alias: indexuegcXwN2raMeta?.alias || [],
    redirect: indexuegcXwN2raMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricingR9dafykHS7Meta?.name ?? "pricing",
    path: pricingR9dafykHS7Meta?.path ?? "/pricing",
    meta: pricingR9dafykHS7Meta || {},
    alias: pricingR9dafykHS7Meta?.alias || [],
    redirect: pricingR9dafykHS7Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy9z5qA1x3mmMeta?.name ?? "privacy-policy",
    path: privacy_45policy9z5qA1x3mmMeta?.path ?? "/privacy-policy",
    meta: privacy_45policy9z5qA1x3mmMeta || {},
    alias: privacy_45policy9z5qA1x3mmMeta?.alias || [],
    redirect: privacy_45policy9z5qA1x3mmMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerIDDEklzyVAMeta?.name ?? "register",
    path: registerIDDEklzyVAMeta?.path ?? "/register",
    meta: registerIDDEklzyVAMeta || {},
    alias: registerIDDEklzyVAMeta?.alias || [],
    redirect: registerIDDEklzyVAMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsGwBP19zWeTMeta?.name ?? undefined,
    path: settingsGwBP19zWeTMeta?.path ?? "/settings",
    meta: settingsGwBP19zWeTMeta || {},
    alias: settingsGwBP19zWeTMeta?.alias || [],
    redirect: settingsGwBP19zWeTMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokenseAPvIqmKEEMeta?.name ?? "settings-access-tokens",
    path: access_45tokenseAPvIqmKEEMeta?.path ?? "access-tokens",
    meta: access_45tokenseAPvIqmKEEMeta || {},
    alias: access_45tokenseAPvIqmKEEMeta?.alias || [],
    redirect: access_45tokenseAPvIqmKEEMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountLm6CcfAoU5Meta?.name ?? "settings-account",
    path: accountLm6CcfAoU5Meta?.path ?? "account",
    meta: accountLm6CcfAoU5Meta || {},
    alias: accountLm6CcfAoU5Meta?.alias || [],
    redirect: accountLm6CcfAoU5Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminchXImMwvXTMeta?.name ?? "settings-admin",
    path: adminchXImMwvXTMeta?.path ?? "admin",
    meta: adminchXImMwvXTMeta || {},
    alias: adminchXImMwvXTMeta?.alias || [],
    redirect: adminchXImMwvXTMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingcntZOcFBc2Meta?.name ?? "settings-billing",
    path: billingcntZOcFBc2Meta?.path ?? "billing",
    meta: billingcntZOcFBc2Meta || {},
    alias: billingcntZOcFBc2Meta?.alias || [],
    redirect: billingcntZOcFBc2Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsF8luLsMhsvMeta?.name ?? "settings-connections",
    path: connectionsF8luLsMhsvMeta?.path ?? "connections",
    meta: connectionsF8luLsMhsvMeta || {},
    alias: connectionsF8luLsMhsvMeta?.alias || [],
    redirect: connectionsF8luLsMhsvMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainYdln9EH9HXMeta?.name ?? "settings-custom-domain",
    path: custom_45domainYdln9EH9HXMeta?.path ?? "custom-domain",
    meta: custom_45domainYdln9EH9HXMeta || {},
    alias: custom_45domainYdln9EH9HXMeta?.alias || [],
    redirect: custom_45domainYdln9EH9HXMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: indexYUOKdAT7EsMeta?.name ?? "settings",
    path: indexYUOKdAT7EsMeta?.path ?? "",
    meta: indexYUOKdAT7EsMeta || {},
    alias: indexYUOKdAT7EsMeta?.alias || [],
    redirect: indexYUOKdAT7EsMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordauLMRNVF1jMeta?.name ?? "settings-password",
    path: passwordauLMRNVF1jMeta?.path ?? "password",
    meta: passwordauLMRNVF1jMeta || {},
    alias: passwordauLMRNVF1jMeta?.alias || [],
    redirect: passwordauLMRNVF1jMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileML7fzCZv7tMeta?.name ?? "settings-profile",
    path: profileML7fzCZv7tMeta?.path ?? "profile",
    meta: profileML7fzCZv7tMeta || {},
    alias: profileML7fzCZv7tMeta?.alias || [],
    redirect: profileML7fzCZv7tMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspaceskd7pzgqFs0Meta?.name ?? "settings-workspaces",
    path: workspaceskd7pzgqFs0Meta?.path ?? "workspaces",
    meta: workspaceskd7pzgqFs0Meta || {},
    alias: workspaceskd7pzgqFs0Meta?.alias || [],
    redirect: workspaceskd7pzgqFs0Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorrtrGImG9UNMeta?.name ?? "subscriptions-error",
    path: errorrtrGImG9UNMeta?.path ?? "/subscriptions/error",
    meta: errorrtrGImG9UNMeta || {},
    alias: errorrtrGImG9UNMeta?.alias || [],
    redirect: errorrtrGImG9UNMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: successt6tGW0o03UMeta?.name ?? "subscriptions-success",
    path: successt6tGW0o03UMeta?.path ?? "/subscriptions/success",
    meta: successt6tGW0o03UMeta || {},
    alias: successt6tGW0o03UMeta?.alias || [],
    redirect: successt6tGW0o03UMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditions9uZgoC1InUMeta?.name ?? "terms-conditions",
    path: terms_45conditions9uZgoC1InUMeta?.path ?? "/terms-conditions",
    meta: terms_45conditions9uZgoC1InUMeta || {},
    alias: terms_45conditions9uZgoC1InUMeta?.alias || [],
    redirect: terms_45conditions9uZgoC1InUMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: editnTKXZpISKpMeta?.name ?? "views-slug-edit",
    path: editnTKXZpISKpMeta?.path ?? "/views/:slug()/edit",
    meta: editnTKXZpISKpMeta || {},
    alias: editnTKXZpISKpMeta?.alias || [],
    redirect: editnTKXZpISKpMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indextLkJmdJsTUMeta?.name ?? "views-slug",
    path: indextLkJmdJsTUMeta?.path ?? "/views/:slug()",
    meta: indextLkJmdJsTUMeta || {},
    alias: indextLkJmdJsTUMeta?.alias || [],
    redirect: indextLkJmdJsTUMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showRUybqr2dHLMeta?.name ?? undefined,
    path: showRUybqr2dHLMeta?.path ?? "/views/:slug()/show",
    meta: showRUybqr2dHLMeta || {},
    alias: showRUybqr2dHLMeta?.alias || [],
    redirect: showRUybqr2dHLMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexOJPDFt1Wh3Meta?.name ?? "views-slug-show",
    path: indexOJPDFt1Wh3Meta?.path ?? "",
    meta: indexOJPDFt1Wh3Meta || {},
    alias: indexOJPDFt1Wh3Meta?.alias || [],
    redirect: indexOJPDFt1Wh3Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: shareVRvUpn0LV5Meta?.name ?? "views-slug-show-share",
    path: shareVRvUpn0LV5Meta?.path ?? "share",
    meta: shareVRvUpn0LV5Meta || {},
    alias: shareVRvUpn0LV5Meta?.alias || [],
    redirect: shareVRvUpn0LV5Meta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexG3jubknGJoMeta?.name ?? "views-create",
    path: indexG3jubknGJoMeta?.path ?? "/views/create",
    meta: indexG3jubknGJoMeta || {},
    alias: indexG3jubknGJoMeta?.alias || [],
    redirect: indexG3jubknGJoMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexGkxoUSHHgLMeta?.name ?? "views",
    path: indexGkxoUSHHgLMeta?.path ?? "/views",
    meta: indexGkxoUSHHgLMeta || {},
    alias: indexGkxoUSHHgLMeta?.alias || [],
    redirect: indexGkxoUSHHgLMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsqDQfuxIXWbMeta?.name ?? "views-uploads",
    path: uploadsqDQfuxIXWbMeta?.path ?? "/views/uploads",
    meta: uploadsqDQfuxIXWbMeta || {},
    alias: uploadsqDQfuxIXWbMeta?.alias || [],
    redirect: uploadsqDQfuxIXWbMeta?.redirect,
    component: () => import("/codebuild/output/src1307910395/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]