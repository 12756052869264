<template>
  <a
    target="_blank"
    rel="opener"
    :href="redirectUrlEndpoint"
  >
    <v-button>
      <div class="flex justify-center items-center text-white">
        <Icon
          name="logos:notion-icon"
          class="w-4 h-4 mr-2 text-black"
        />
        <span class="">
          {{ text }}
        </span>
      </div>

      <modal
        v-if="errorMessage == 'workspace_already_existing'"
        :show="showModalError"
        @close="closeErrorModal"
      >
        <div class="overflow-hidden">
          <h2 class="text-center text-nt-blue text-2xl font-bold z-10 mt-6">
            Workspace already connected
          </h2>

          <div class="flex items-center justify-center">
            <div class="flex-grow my-10 sm:my-0 sm:px-10">
              <p class="mb-0 font-medium mt-6">
                This Notion workspace has already been associated with another NoteForms account.
                <template v-if="errorOwner">
                  <span class="font-semibold">{{ errorOwner.name }} ({{ errorOwner.email }})</span> is the current owner
                  of this workspace.
                </template>
                You have two options:
                <ul class="list-decimal list-inside">
                  <li class="mt-4">
                    You or the current owner of the workspace can upgrade your account by subscribing to our Team
                    plan.
                    This will allow both of you to collaborate on forms for this workspace.
                  </li>
                  <li class="mt-4">
                    You can ask the current owner to remove the workspace from his NoteForms account. This will "free"
                    the workspace, allowing you to use it in NoteForms. <a
                      href="#"
                      @click.prevent="crisp.openHelpdeskArticle('how-to-disconnect-my-notion-workspace-from-notionforms-1fn53x')"
                    >Learn
                      how to remove a workspace from a NoteForms account</a>.
                  </li>
                </ul>
              </p>
              <p class="mt-4">
                <a
                  href="#"
                  @click.prevent="openChat"
                >Talk to us</a> if you have any question.
              </p>
              <div class="mt-4 text-center sm:text-left">
                <v-button
                  v-if="showUpgradePlan"
                  target="_blank"
                  :to="{ name: 'pricing' }"
                  color="nt-blue"
                  class="px-10 mr-2"
                >
                  Upgrade Subscription
                </v-button>
                <v-button
                  color="gray"
                  shade="light"
                  @click="closeErrorModal"
                >
                  Close
                </v-button>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <modal
        v-else
        :show="showModalError"
        @close="closeErrorModal"
      >
        <div class="overflow-hidden">
          <h2 class="text-center text-nt-blue text-4xl font-bold z-10 mt-6 mb-4">
            Something went wrong.
          </h2>

          <div class="flex items-center justify-center">
            <div class="flex-grow my-10 sm:my-0 sm:px-10">
              <p v-if="errorNotionUser">This NoteForms account belongs to the Notion user below. Your plan does not
                allow collaboration, please
                <router-link
                  v-track.upgrade_notion_user_click
                  target="_blank"
                  :to="{name:'pricing'}"
                >upgrade to the Team plan.
                </router-link>
              </p>
              <div
                v-if="errorNotionUser"
                class="flex border my-3 p-3 rounded-md w-max gap-x-2 mx-auto"
              >
                <img
                  v-if="errorNotionUser.avatar_url && isUrl(errorNotionUser.avatar_url)"
                  loading="lazy"
                  :src="errorNotionUser.avatar_url"
                  :alt="`${errorNotionUser.avatar_url} icon`"
                  class="flex-shrink-0 h-6 w-6 rounded-full"
                >
                <div
                  v-else
                  class="rounded-full bg-nt-blue-lighter h-6 w-6 text-sm text-center"
                  v-text="errorNotionUser.name.charAt(0)"
                />
                <p>{{ errorNotionUser.name }}</p>
              </div>
              <p class="mb-0 font-medium">
                {{ errorMessage }} <a
                  href="#"
                  @click.prevent="openChat"
                >Talk to us</a>.
              </p>
              <div class="mt-4 text-center sm:text-left">
                <v-button
                  v-if="showRetry"
                  color="nt-blue"
                  class="px-10 mr-2"
                  @click="retry"
                >
                  Retry
                </v-button>
                <v-button
                  v-if="showUpgradePlan"
                  target="_blank"
                  :to="{ name: 'pricing' }"
                  color="nt-blue"
                  class="px-10 mr-2"
                >
                  Upgrade Subscription
                </v-button>
                <v-button
                  color="gray"
                  shade="light"
                  @click="closeErrorModal"
                >
                  Close
                </v-button>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </v-button>
  </a>
</template>

<script>
import { useBroadcastChannel } from '@vueuse/core'
import { fetchAllWorkspaces } from '~/stores/workspaces.js'
import { isUrl } from '~/lib/utils.js'

export default {
  name: 'LoginWithNotion',
  components: {},
  props: {
    text: { type: String, default: 'Login with Notion' },
    guestWorkspaceUser: { type: Boolean, default: false }
  },
  emits: ['success'],
  setup () {
    const authStore = useAuthStore()
    const workspacesStore = useWorkspacesStore()
    const authBroadcast = useBroadcastChannel('auth')
    return {
      authStore,
      workspacesStore,
      authBroadcast,
      crisp: useCrisp(),
      broadcastData: useBroadcastChannel('auth').data
    }
  },

  data () {
    return {
      showModalError: false,
      showRetry: false,
      showUpgradePlan: false,
      errorMessage: null,
      errorOwner: null,
      errorNotionUser: null
    }
  },

  computed: {
    redirectUrlEndpoint () {
      return `${useRuntimeConfig().public.apiBase}notion/redirect${(this.guestWorkspaceUser) ? '?guest_workspace_user=true' : ''}`
    }
  },

  watch: {
    broadcastData (data) {
      if (data)
        this.onMessage(data)
    }
  },

  methods: {
    isUrl,
    closeErrorModal () {
      this.showModalError = false
      this.errorMessage = null
      this.showUpgradePlan = false
      this.showRetry = false
    },

    openChat () {
      this.crisp.sendTextMessage(`Hi! I need help to connect my Notion workspace. ${
        this.errorOwner.name}(${this.errorOwner.email}) has already connected it to his NoteForms account.`)
    },

    async onMessage (message) {
      if (import.meta.server || message.source !== 'notion_tools')
        return

      // Handle errors
      if (message.type !== 'success') {
        this.showModalError = true
        this.showRetry = message.retry
        this.showUpgradePlan = message.upgrade
        this.errorMessage = message.message
        this.errorOwner = message.owner
        this.errorNotionUser = message.notion_user
        return
      }

      // For guest users, only emit success
      if (this.guestWorkspaceUser) {
        useAmplitude().logEvent('notion_guest_logged_in', {
          workspace_id: message.workspace.id,
          workspace_name: message.workspace.name
        })
        this.$emit('success', message.cookie_value)
        return
      }

      // For regular users, re-fetch their data
      const workspaces = await fetchAllWorkspaces()
      this.workspacesStore.set(workspaces.data.value)

      const userData = await noteFormsFetch('user')
      this.authStore.setUser(userData)

      useAmplitude().logEvent('notion_workspace_added', {
        workspace_id: message.workspace.id,
        workspace_name: message.workspace.name
      })
      this.crisp.pushEvent('notion-connected')
      this.$emit('success', message.workspace)
    }
  }
}
</script>
